@value colorGrayDog, colorGrey100, colorBase, colorWhite from '~Styles/vars/colors.css';

.root {
  display: block;
  margin-top: 6px;
  width: 100%;
}

.list {
  margin-top: 40px;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.withNewCampaignTabs {
  .list {
    margin-top: 24px;
    gap: 20px;
  }
}

.spacer {
  flex: 1;
}

.preloader {
  margin: 40px auto;
}

.stubWrap {
  margin: 40px auto;
  @mixin center;
}

.stubDescr {
  margin-left: 40px;
  width: 280px;
}

.lastUpdated {
  display: inline-block;
  cursor: default;

  .lastUpdatedText {
    color: #63696a;
  }
}

.newHeader {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.header {
  background: colorWhite;
  border-radius: 16px;
  padding: 12px 8px;
  margin-top: 16px;
}

.report {
  width: 100%;
  margin-top: 40px;
}

.reportContent {
  width: 430px;
  margin: 0 auto;
  text-align: center;
}

.reportDescr {
  margin-top: 20px;
  margin-bottom: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.authorStatisticBtn {
  margin-right: 20px;
}

.action {
  display: flex;
  align-items: center;
}

.views {
  margin-left: 20px;
  display: flex;
}

.view {
  size: 40px;
  @mixin center;
  cursor: pointer;

  svg {
    path {
      fill: colorGrayDog;
    }
  }
}

.viewActive {
  cursor: default;

  svg {
    path {
      fill: colorBase;
    }
  }
}

.preloader {
  margin: 40px auto;
}

.stubWrap {
  margin: 40px auto;
  @mixin center;
}

.stubDescr {
  margin-left: 40px;
  width: 280px;
}

.lastUpdated {
  display: inline-block;
  cursor: default;
}

.lastUpdatedText {
  color: colorGrey100;
}

.stats {
  display: flex;
  flex: 1;
}

.statItem {
  flex: 1;
}