@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  width: desktopMinWidth;
  margin: 0 auto;

  .title {
    margin-bottom: 12px;
  }
}

.content {
  margin-top: 8px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.secton {
  margin-bottom: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
